// import WhyGetInvolved from '../../components/BestSchoolsAwards/WhyGetInvolved/WhyGetInvolved'
// import Categories from '../../components/BestSchoolsAwards/Categories/Categories'
import BSAContent from '../../components/BestSchoolsAwards/Content/Content'
import BSAFooter from '../../components/BestSchoolsAwards/Footer/Footer'
import TideHeaderLogo from '../../components/BestSchoolsAwards/HeaderLogo/TideHeaderLogo'
import Nav from '../../components/BestSchoolsAwards/Nav/Nav'
import React from 'react'
import { Helmet } from 'react-helmet'
import * as ContentStyles from '../../components/BestSchoolsAwards/Content/Content.module.scss'
import { StaticImage } from 'gatsby-plugin-image'
// import TestimonialSlider from '../../components/BestSchoolsAwards/TestimonialSlider/TestimonialSlider'

const WIBWinner = () => {
  return (
    <>
      <Helmet>
        <title>She Means Business | Muddy Stilettos</title>
      </Helmet>
      <Nav site="tide" theme="dark" />
      <TideHeaderLogo />
      <BSAContent>
        <h1>The results are in!</h1>
        <p>
          Drumroll please! There were hundreds of inspiring applications from
          incredible female founders – a huge thank you to the brilliant women
          in business who entered. The judging panel spent weeks deliberating to
          shortlist three finalists who rocked up at Thames Lido in Reading to
          pitch their ideas. But only one woman could be crowned, so without
          further ado, the winner is…
        </p>
        <p className={ContentStyles.WIBStyle}>
          JENNY CLARK, FOUNDER OF{' '}
          <strong>
            <a
              href="https://www.thewildtimes.co/"
              target="_blank"
              rel="noreferrer"
            >
              THE WILD TIMES
            </a>
          </strong>
        </p>
        <StaticImage
          src="../../../static/assets/women-in-business/jen-clark.jpeg"
          alt="Jenny Clark"
          placeholder="blurred"
          layout="fullWidth"
        />
        <p>
          Jenny impressed the judges with her confidence and clear strategy for
          taking{' '}
          <a
            href="https://www.thewildtimes.co/"
            target="_blank"
            rel="noreferrer"
          >
            The Wild Times
          </a>{' '}
          to the next level, presenting a detailed marketing plan, identifying
          new audiences and demonstrating her hustle and tenacity. Splitting her
          time between London and Hampshire, Jenny&apos;s retreats focus on the
          wellbeing benefits of being outdoors – helping guests rest, ground,
          re-wild and connect with nature. Having worked three freelance jobs
          alongside her sell-out retreats, the £10,000 investment will give her
          the financial springboard to launch a teen and team building retreats.
        </p>
        <h3>Runners up</h3>
        <p className={ContentStyles.WIBStyle}>
          NATALIE BENNETT, FOUNDER OF{' '}
          <strong>
            <a
              href="https://www.etsy.com/uk/shop/CuriousFair"
              target="_blank"
              rel="noreferrer"
            >
              CURIOUS FAIR
            </a>
          </strong>
        </p>
        <StaticImage
          src="../../../static/assets/women-in-business/curious-fair.jpg"
          alt="Curious Fair"
          placeholder="blurred"
          layout="fullWidth"
        />
        <p>
          Based in Norwich in Norfolk, Natalie makes luxury masks and
          accessories inspired by nature. She sells her work via{' '}
          <a
            href="http://www.etsy.com/uk/shop/CuriousFair"
            target="_blank"
            rel="noreferrer"
          >
            Etsy
          </a>{' '}
          and her pieces are in demand for masquerade balls, woodland weddings,
          festivals and theatre productions. Right now, you can spot her
          creations in Emerald Fennell&apos;s new film Saltburn, starring Barry
          Keoghan, Rosamund Pike and Carey Mulligan. Natalie trained in Costume
          and Theatre Design and her whimsical wares are exquisite.
        </p>
        <p className={ContentStyles.WIBStyle}>
          OZGE AYGUNOGLU, FOUNDER OF{' '}
          <strong>
            <a
              href="https://www.remakematerials.com"
              target="_blank"
              rel="noreferrer"
            >
              REMAKE
            </a>
          </strong>
        </p>
        <StaticImage
          src="../../../static/assets/women-in-business/remake.jpg"
          alt="Curious Fair"
          placeholder="blurred"
          layout="fullWidth"
        />
        <p>
          <a
            href="https://www.remakematerials.com"
            target="_blank"
            rel="noreferrer"
          >
            ReMAKE
          </a>{' '}
          is a start-up that will launch a marketplace app for leftover
          renovation materials. Construction creates a third of the world&apos;s
          waste and this new platform will provide renovators with a sustainable
          and affordable place to buy materials. Think Vinted for home
          improvement and corporate contractors. Ozge is currently raising
          capital to fund the app development.
        </p>
        <p>
          <a
            href="https://muddystilettos.co.uk/life/finance/muddy-stilettos-tide-she-means-business-award-winner"
            target="_blank"
            rel="noreferrer"
          >
            Read more
          </a>{' '}
          about the first ever SHE MEANS BUSINESS finalist event in association
          with Tide.
        </p>
      </BSAContent>
      {/* <WhyGetInvolved /> */}
      <BSAFooter />
    </>
  )
}

export default WIBWinner
